import React, { useRef, useState } from "react"
import * as S from "../../styles"
import { Form, FloatingLabel, Row, Col } from "react-bootstrap"
import ReelerButton from "../../../../../commons/ReelerButton"
import { useDispatch, useSelector } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import axios from "axios"
import Text from "../../../../../commons/Text"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"

export default function AssetGenAi() {
  const asset = useSelector(selectActiveAsset)
  const promptRef = useRef()
  const temperatureRef = useRef()
  const topPRef = useRef()
  const topKRef = useRef()
  const [isLoading, setIsLoading] = useState(null)
  const [result, setResult] = useState(null)
  const dispatch = useDispatch()
  const [experiment, setExperiment] = useState(null)

  const handleRunAi = async () => {
    setIsLoading(true)
    setResult(null)
    try {
      const data = {
        assetId: asset.id,
      }

      if (promptRef.current.value) {
        data.prompt = promptRef.current.value
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_FIREBASE_HTTPS_FUNCTIONS}/contentcurator`,
        headers: {},
        data: data,
      }

      const response = await axios.request(config)

      const updateData = response.data

      console.log(updateData)

      dispatch(updateAsset({ ...asset, ...updateData }))

      let x = response?.data?.contentCurator

      // check if response.data is an object
      let pretty
      if (typeof x === "object") {
        pretty = JSON.stringify(x, undefined, 4)
      } else {
        const obj = JSON.parse(x.trim())
        pretty = JSON.stringify(obj, undefined, 4)
      }

      setResult(pretty)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const handleExperimentAi = async () => {
    setIsLoading(true)
    setResult(null)
    try {
      const data = {
        assetId: asset.id,
      }

      if (promptRef.current.value) {
        data.prompt = promptRef.current.value
      }

      if (temperatureRef.current.value) {
        data.temperature = temperatureRef.current.value
      }

      if (topPRef.current.value) {
        data.topP = topPRef.current.value
      }

      if (topKRef.current.value) {
        data.topK = topKRef.current.value
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_FIREBASE_HTTPS_FUNCTIONS}/experimentwithgenkit`,
        headers: {},
        data: data,
      }

      const response = await axios.request(config)
      console.log(response.data)
      let x = response?.data

      // // check if response.data is an object
      // let pretty
      // if (typeof x === "object") {
      //   pretty = JSON.stringify(x, undefined, 4)
      // } else {
      //   const obj = JSON.parse(x.trim())
      //   pretty = JSON.stringify(obj, undefined, 4)
      // }

      setResult(x)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  return (
    <S.ModalTabContainer>
      <Form.Group className="mb-3">
        <Form.Label>AI Prompt</Form.Label>
        <Form.Control
          as="textarea"
          rows={10}
          ref={promptRef}
          placeholder="Enter test prompt"
          size="sm"
        />
        <div className="d-flex flex-row align-items-center mt-3">
          <FloatingLabel controlId="floatingInput" label="Temperature">
            <Form.Control type="number" ref={temperatureRef} size="sm" />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Top P">
            <Form.Control type="number" ref={topPRef} size="sm" />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Top K">
            <Form.Control type="number" ref={topKRef} size="sm" />
          </FloatingLabel>
        </div>
        <div className="d-flex flex-row align-items-center mt-3">
          <ReelerButton
            text="Run AI"
            dispatch={handleRunAi}
            loading={isLoading}
            disabled={isLoading}
            styleClass="btn-main me-2"
          />
          <ReelerButton
            text="Experiment with AI"
            dispatch={handleExperimentAi}
            loading={isLoading}
            disabled={isLoading}
            styleClass="btn-secondary"
          />
        </div>
      </Form.Group>
      {result && (
        <Form.Group className="mb-3">
          <Form.Label>Result</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            style={{ height: "400px" }}
            size="sm"
            value={result}
            disabled
          />
        </Form.Group>
      )}
    </S.ModalTabContainer>
  )
}
